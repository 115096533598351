import React, { useState, useEffect } from "react"
import { navigate, graphql } from "gatsby"
import { Formik } from "formik"
import * as _ from "lodash"
import * as Yup from "yup"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import RadioGroup from "@components/RadioGroup"
import SEO from "@components/seo"
import TextInput from "@components/TextInput"
import SelectInput from "@components/Select"
import moment from "moment"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import get from "lodash/get"
import { base } from "@helpers/airtable"
import {
  FormHead,
  Form,
  FormSection,
  FieldGroup,
  FormActions,
  Textarea
} from "@components/Form"
import { authCheckRedirect } from "@helpers/auth"
import { mapOptions } from "@helpers/data"
import Back from "@components/Back"

const initialFormState = {
  product_name: "",
  product_type: "",
  state_territory: "",
  sector: "",
  stage: "",
  mapped_to: "",
  potential_reach: 0,
  identifiedDate: moment(),
  interestDate: moment(),
  developmentDate: moment(),
  liveDate: moment(),
  URL: "",
  comments: ""
}

const validationSchema = Yup.object().shape({
  product_name: Yup.string().required("This is required"),
  product_type: Yup.string().required("This is required"),
  stage: Yup.string().required("This is required"),
  state_territory: Yup.string().required("This is required"),
  sector: Yup.string().required("This is required")
})

const AddProductPage = ({ user, data, ...props }) => {
  const [submissionError, setSubmissionError] = useState(false)

  const [calendarFocus, setCalendarFocus] = useState({
    identified: false,
    interest: false,
    development: false,
    live: false
  })

  useEffect(() => {
    authCheckRedirect()
  }, [])

  const stageOrder = ["Identified", "Interest", "Development", "Live"]

  let stageOptions = mapOptions(data.stageOptions.edges)

  stageOptions = _.sortBy(stageOptions, function(obj) {
    return _.indexOf(stageOrder, obj.label)
  })

  const productTypeOptions = mapOptions(
    data.productTypeOptions.edges,
    "project_type"
  )

  const stateTerritoryOptions = mapOptions(
    data.stateTerritoryOptions.edges,
    "state_territory"
  )
  const sectorOptions = mapOptions(data.sectorOptions.edges, "sector")

  const onCalendarFocusChange = (e, type) => {
    setCalendarFocus({ ...calendarFocus, [type]: e.focused })
  }

  const createProduct = async values => {
    const product = {
      fields: {
        "Product name": values.product_name,
        "Product type": [values.product_type],
        "State / Territory": [values.state_territory],
        Sector: [values.sector],
        "Current stage": [values.stage],
        Identified: values.identifiedDate.format("YYYY-MM-DD"),
        Interest: values.interestDate.format("YYYY-MM-DD"),
        Development: values.developmentDate.format("YYYY-MM-DD"),
        Live: values.liveDate.format("YYYY-MM-DD"),
        "Potential Reach": values.potential_reach,
        URL: values.URL,
        Comments: values.comments,
        "Mapped to": values.mapped_to
      }
    }

    return base("Products").create([product])
  }

  const handleSubmit = async (values, actions) => {
    try {
      await createProduct(values)
      actions.setSubmitting(false)
      navigate("/view-products")
    } catch (e) {
      setSubmissionError(true)
      actions.setSubmitting(false)
      console.log(e, "error")
    }
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Home" />
      <Container pt={[7]}>
        <section>
          <Back to="/view-products" />
          <FormHead title="Add product" />
          <Formik
            initialValues={initialFormState}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleBlur,
              submitForm,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
              resetForm,
              values,
              errors,
              touched
            }) => (
              <Form>
                <FormSection title="Basics">
                  <Columns>
                    <Column>
                      <FieldGroup
                        required
                        name="product_name"
                        label="Product name"
                      >
                        <TextInput
                          name="product_name"
                          value={values.product_name}
                          onChange={handleChange}
                          error={
                            get(touched, "product_name") &&
                            get(errors, "product_name") &&
                            get(errors, "product_name")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>

                    <Column width={[1, 0.5]}>
                      <FieldGroup
                        required
                        name="product_type"
                        label="Product type"
                      >
                        <SelectInput
                          name="product_type"
                          value={values.product_type}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "product_type") &&
                            get(errors, "product_type") &&
                            get(errors, "product_type")
                          }
                          options={productTypeOptions}
                        />
                      </FieldGroup>
                    </Column>

                    <Column width={[1, 0.5]}>
                      <FieldGroup
                        required
                        name="state_territory"
                        label="State/Territory"
                      >
                        <SelectInput
                          name="state_territory"
                          value={values.state_territory}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "state_territory") &&
                            get(errors, "state_territory") &&
                            get(errors, "state_territory")
                          }
                          options={stateTerritoryOptions}
                        />
                      </FieldGroup>
                    </Column>
                    <Column width={[1, 0.5]}>
                      <FieldGroup required name="sector" label="Sector">
                        <SelectInput
                          name="sector"
                          value={values.sector}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={
                            get(touched, "sector") &&
                            get(errors, "sector") &&
                            get(errors, "sector")
                          }
                          options={sectorOptions}
                        />
                      </FieldGroup>
                    </Column>

                    <Column width={[1, 0.5]}>
                      <FieldGroup name="mapped_to" label="Mapped to">
                        <TextInput
                          name="mapped_to"
                          value={values.mapped_to}
                          onChange={handleChange}
                          error={
                            get(touched, "mapped_to") &&
                            get(errors, "mapped_to") &&
                            get(errors, "mapped_to")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection title="Status">
                  <Columns>
                    <Column>
                      <FieldGroup required name="stage" label="Stage">
                        <RadioGroup
                          name="stage"
                          values={values}
                          options={stageOptions}
                          onChange={handleChange}
                          error={
                            get(touched, "stage") &&
                            get(errors, "stage") &&
                            get(errors, "stage")
                          }
                          onBlur={handleBlur}
                        />
                      </FieldGroup>
                    </Column>
                    <Column>
                      <Columns>
                        <Column width={0.25}>
                          <FieldGroup
                            name="identifiedDate_input"
                            label="Identified date"
                          >
                            <SingleDatePicker
                              id="identifiedDate_input"
                              date={values.identifiedDate}
                              focused={calendarFocus.identified}
                              onDateChange={e =>
                                setFieldValue("identifiedDate", e)
                              }
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "identified")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                        <Column width={0.25}>
                          <FieldGroup
                            name="interestDate_input"
                            label="Interest date"
                          >
                            <SingleDatePicker
                              id="interestDate_input"
                              date={values.interestDate}
                              focused={calendarFocus.interest}
                              onDateChange={e =>
                                setFieldValue("interestDate", e)
                              }
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "interest")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                        <Column>
                          <FieldGroup
                            name="developmentDate_input"
                            label="Development date"
                          >
                            <SingleDatePicker
                              id="developmentDate_input"
                              date={values.developmentDate}
                              focused={calendarFocus.development}
                              onDateChange={e =>
                                setFieldValue("developmentDate", e)
                              }
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "development")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                        <Column>
                          <FieldGroup name="liveDate_input" label="Live date">
                            <SingleDatePicker
                              id="liveDate_input"
                              date={values.liveDate}
                              focused={calendarFocus.live}
                              onDateChange={e => setFieldValue("liveDate", e)}
                              onFocusChange={e =>
                                onCalendarFocusChange(e, "live")
                              }
                              numberOfMonths={1}
                            />
                          </FieldGroup>
                        </Column>
                      </Columns>
                    </Column>
                  </Columns>
                </FormSection>

                <FormSection title="Stats">
                  <Columns>
                    <Column>
                      <FieldGroup
                        name="potential_reach"
                        label="Potential reach"
                      >
                        <TextInput
                          type="number"
                          name="potential_reach"
                          value={values.potential_reach}
                          onChange={handleChange}
                        />
                      </FieldGroup>
                    </Column>
                  </Columns>
                </FormSection>
                <FormSection>
                  <FieldGroup name="comments" label="Comments">
                    <Textarea
                      name="comments"
                      id="comments"
                      value={values.comments}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FieldGroup>

                  <FieldGroup name="URL" label="URL">
                    <TextInput
                      name="URL"
                      value={values.URL}
                      onChange={handleChange}
                    />
                  </FieldGroup>
                </FormSection>

                <FormActions
                  onSubmitClick={submitForm}
                  onClearClick={() => resetForm(initialFormState)}
                  submitDisabled={isSubmitting}
                  error={submissionError}
                />
              </Form>
            )}
          </Formik>
        </section>
      </Container>
    </Layout>
  )
}

export default AddProductPage

export const query = graphql`
  query {
    productTypeOptions: allAirtable(
      filter: { table: { eq: "DL_Product_Type" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    stateTerritoryOptions: allAirtable(
      filter: { table: { eq: "DL_State_Territory" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    sectorOptions: allAirtable(filter: { table: { eq: "DL_SECTOR" } }) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    stageOptions: allAirtable(
      filter: { table: { eq: "DL_Product_Development_Stage" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
